import axios from 'axios';
import { applicationActions } from './slice';

export const fetchMerchantDetails = ({ businessId }) => {
  return async (dispatch) => {
    try {
      const { data: { data } } = await axios.get(`https://api.popscom.life/merchant/business/${businessId}`);
      dispatch(
        applicationActions.setMerchant({
          merchant: data,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};


export const postCustomerFeedback = ({ audio, language, businessId, place }) => {
  return async (dispatch) => {
    try {
      const form = new FormData();
      form.append("audio", audio);
      form.append("language", language);
      form.append("businessId", businessId);
      form.append("place", place);

      const { data: { data } } = await axios.post(`https://api.popscom.life/feedback`, form, {
        "Content-Type": "multipart/form-data",
      });

      dispatch(applicationActions.setFeedback({ feedback: data }));

    } catch (error) {
      throw error;
    }
  };
};

export const fetchFeedback = (feedbackId) => {
  return async (dispatch) => {
    try {
      const { data: { data } } = await axios.get(`https://api.popscom.life/feedback/${feedbackId}`);
      dispatch(
        applicationActions.setFeedback({
          feedback: data,
        })
      );
    } catch (error) {
      throw error;
    }
  };
};

